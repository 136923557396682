<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <v-text-field
          v-model="form.model_type"
          label="Type"
          outlined
          disabled
          :rules="[required]"
        ></v-text-field>

        <v-text-field
          v-model="form.notes"
          label="Required: Notes on how you intend to use it."
          outlined
          :rules="[required]"
        ></v-text-field>

        <v-col :cols="12">
          <h3 class="mt-2">
            Valid Until
          </h3>
          <date-time-picker v-model="form.expires_at">
          </date-time-picker>
        </v-col>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { now } from '@/@core/utils/filter'
import { DateTimePicker } from '@/components'
import { required } from '@core/utils/validation'
import { } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  components: { DateTimePicker },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const initialForm = {
      model_type: 'order',
      notes: props.resource ? props.resource.notes : '',
      expires_at: props.resource ? props.resource.expires_at : now(),
    }

    initialForm.expires_at = now(initialForm.expires_at).toString()

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    return {
      form,
      formElem,

      validate,
      required,
    }
  },
}
</script>
