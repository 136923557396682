var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-list"}},[(_vm.formReady)?_c('model-token-aside',{attrs:{"resource":_vm.resource},on:{"changed":function($event){_vm.loadModelTokens(); _vm.isAddNewModelTokenSidebarActive = false}},model:{value:(_vm.isAddNewModelTokenSidebarActive),callback:function ($$v) {_vm.isAddNewModelTokenSidebarActive=$$v},expression:"isAddNewModelTokenSidebarActive"}}):_vm._e(),_c('v-row',{staticClass:"mb-5"},_vm._l((_vm.totalLocal),function(total){return _c('v-col',{key:total.total,attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-card',[_c('v-card-text',{staticClass:"d-flex align-center justify-space-between pa-4"},[_c('div',[_c('h2',{staticClass:"font-weight-semibold mb-1"},[_vm._v(" "+_vm._s(total.total)+" ")]),_c('span',[_vm._v(_vm._s(total.title))])])])],1)],1)}),1),_c('v-card',[_c('v-card-title',[_vm._v(" Model Token (for guest actions) ")]),_c('v-row',{staticClass:"px-2 ma-0"},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6"}}),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"d-flex justify-end flex-wrap"},[_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.addNewResoure.apply(null, arguments)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Add New ModelToken")])],1)],1)])],1),_c('v-divider',{staticClass:"mt-4"}),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.modelTokenListTable,"options":_vm.options,"server-items-length":_vm.totalListTable,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.id))])]}},{key:"item.expires_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("date")(item.expires_at)))])]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.setResource(item)}}},[_vm._v(" "+_vm._s(item.code)+" ")])]}},{key:"item.notes",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.setResource(item)}}},[_vm._v(" "+_vm._s(item.notes)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.setResource(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('span',[_vm._v("Update")])],1)],1)],1)],1)]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }