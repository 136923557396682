<template>
  <div id="user-list">
    <!-- app drawer -->
    <model-token-aside
      v-if="formReady"
      v-model="isAddNewModelTokenSidebarActive"
      :resource="resource"
      @changed="loadModelTokens(); isAddNewModelTokenSidebarActive = false"
    ></model-token-aside>

    <!-- user total card -->
    <v-row class="mb-5">
      <v-col
        v-for="total in totalLocal"
        :key="total.total"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        Model Token (for guest actions)
      </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- search filter -->
        <v-col
          cols="12"
          sm="6"
          class="d-flex"
        >
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
          <div class="d-flex justify-end flex-wrap">
            <v-btn
              color="primary"
              class="mb-4 me-3"
              :loading="loading"
              @click.stop="addNewResoure"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
              <span>Add New ModelToken</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="modelTokenListTable"
        :options.sync="options"
        :server-items-length="totalListTable"
        :loading="loading"
      >
        <!-- ID -->
        <template #[`item.id`]="{item}">
          <span>{{ item.id }}</span>
        </template>

        <template #[`item.expires_at`]="{item}">
          <span>{{ item.expires_at | date }}</span>
        </template>

        <!-- Key -->
        <template #[`item.code`]="{item}">
          <span
            class="cursor-pointer"
            @click="setResource(item)"
          >
            {{ item.code }}
          </span>
        </template>

        <!-- Name -->
        <template #[`item.notes`]="{item}">
          <span
            class="cursor-pointer"
            @click="setResource(item)"
          >
            {{ item.notes }}
          </span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                @click="setResource(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                  <span>Update</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { date } from '@core/utils/filter'
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiPencil,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'
import ModelTokenAside from './ModelTokenAside.vue'

import useModelTokenList from './useModelTokenList'

export default {
  filters: { date },
  components: {
    ModelTokenAside,
  },
  setup() {
    const {
      modelTokenListTable,
      tableColumns,
      searchQuery,
      totalListTable,
      loading,
      options,
      totalLocal,
      selectedRows,

      loadModelTokens,
    } = useModelTokenList()

    const isAddNewModelTokenSidebarActive = ref(false)
    const formReady = ref(true)
    const resource = ref(false)

    const setResource = token => {
      resource.value = token
      isAddNewModelTokenSidebarActive.value = true
      formReady.value = false
      setTimeout(() => {
        formReady.value = true
      })
    }

    const addNewResoure = () => {
      resource.value = null
      formReady.value = false
      isAddNewModelTokenSidebarActive.value = true
      setTimeout(() => {
        formReady.value = true
      })
    }

    return {
      modelTokenListTable,
      tableColumns,
      searchQuery,
      totalListTable,

      // categoriesOptions,
      loading,
      options,
      totalLocal,
      isAddNewModelTokenSidebarActive,
      formReady,
      resource,
      selectedRows,
      addNewResoure,

      avatarText,
      loadModelTokens,
      setResource,

      // icons
      icons: {
        mdiPencil,
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
